
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  name: "StudentsListDialog"
})
export default class extends Vue {
  @Prop() students!: any[];
  dialog = false;

  open() {
    this.dialog = true;
  }
}
