
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop() course!: any;

  loading = false;
  error = '';
  done = false;
  dialog = false;

  get firstMeeting() {
    return this.course.meetings[0];
  }

  async leave() {
    this.loading = true;
    this.error = '';
    try {
      await Vue.$axios.delete(`/classes/${this.course.id}/cancel-reservation`);
      this.done = true;
    } catch (err) {
      this.error = 'Failed to leave class';
    }
    this.loading = false;
  }

  open() {
    this.dialog = true;
  }

  finalize() {
    window.location.reload();
  }
}
