
import { Vue, Component, Prop } from 'vue-property-decorator';
import StudentsListDialog from './StudentsListDialog.vue';
import LeaveClassDialog from './LeaveClassDialog.vue';
import CancelClassDialog from './CancelClassDialog.vue';
// import moment from 'moment';
import { Auth, Rates } from '@/store/modules';
import { User } from '@/store/auth';
import NewMessageDialog from './NewMessageDialog.vue';

@Component({
  name: "ClassRow",
  components: {
    StudentsListDialog,
    LeaveClassDialog,
    CancelClassDialog,
    NewMessageDialog
  }
})
export default class extends Vue {
  @Prop() course: any;
  @Prop({ default: () => false }) teacher!: boolean;
  expand = false;

  get series() {
    return this.course.meetings.length > 1;
  }

  get firstMeeting() {
    return this.course.meetings[0];
  }

  get teacherAvatar(): string {
    return this.course.teacher.avatarUrl;
  }

  get teacherId(): string {
    return this.course.teacherId;
  }

  hasMeetingFinished(meeting: any) {
    return new Date(meeting.date).valueOf() + meeting.length * 60 * 1000 + 15 * 60 * 1000 < Date.now();
  }

  isMeetingInProgress(meeting: any) {
    return meeting.status === 'live' && Date.now() > new Date(meeting.date).valueOf();
  }

  selectedClassStudents: any[] = [];

  addReviewDialog = false;
  loadingAddReview = false;
  comment = '';
  rating = 0;
  error = false;
  hasUserRated = false;
  avgRating = 0;
  rateDialogError = '';

  mounted() {
    if (this.teacher) {
      this.avgRating = Number(this.course.avgRating);
    } else {
      this.getUserRate()
    }

    this.sortMeetings();
  }

  sortMeetings() {
    this.course.meetings.sort((a: any, b: any) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
  }

  getStudents(course: any) {
    this.selectedClassStudents = course.seats.map((seat: any) => seat.user).filter((user: any) => user.id !== (Auth.user as User).id);
  }

  viewStudents(course: any) {
    this.getStudents(course);
    (this.$refs.students as any).open();
  }

  async getUserRate() {
    // eslint-disable-next-line
    // @ts-ignore
    const rate = await Rates.getRates({ teacherId: this.teacherId, classId: this.course.id, userId: Auth.user.id });
    this.hasUserRated = !!rate[0];
    this.comment = rate[0].comment;
    this.rating = rate[0].rating;
    this.avgRating = rate[0].class.avgRating;
  }

  async openRateDialog() {
    this.rateDialogError = '';
    if (this.teacher) {
      return;
    }
    this.addReviewDialog = true;
  }

  openSendMessageDialog() {
    this.getStudents(this.course);
    (this.$refs.message as any).state(true);
  }

  async addReview() {
    this.loadingAddReview = true;

    if (this.rating < 1) {
      this.rateDialogError = 'Please choose a rating for the Class by clicking on the stars';
      this.loadingAddReview = false;
      return;
    }

    try {
      await Rates.addRate({ payload: { teacherId: this.teacherId, classId: this.course.id, rating: Number(this.rating), comment: this.comment }});
    } catch(err) {
      this.error = err.message;
    }

    this.hasUserRated = true;
    this.addReviewDialog = false;
    this.loadingAddReview = false;
    this.comment = '';
    this.rating = 0;
    this.getUserRate();
  }
}
