
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import MessageInput from '@/components/MessageInput.vue';
import { Chat } from '@/store/modules';

@Component({ components: { MessageInput }})
export default class extends Vue {
  @Prop() messageTo?: any
  @Ref('MessageInput') messageInput!: MessageInput;

  dialog = false;
  sending = false;

  state(open: boolean) {
    this.dialog = open;
  }

  async onSend(message: { text: string; attachments: any[] }) {
    this.sending = true;
    try {
      if (this.messageTo.students) {
        const sendMessagePromises = this.messageTo.students.map((student: any) => Chat.sendMessageToStudent({ classId: this.messageTo.classId, text: message.text, attachments: message.attachments, studentId: student.id }).catch((err: any) => console.warn(err.message)));
        await Promise.all(sendMessagePromises);
      } else {
        await Chat.sendMessageToTeacher({ teacherId: this.messageTo.teacherId, classId: this.messageTo.classId, text: message.text, attachments: message.attachments });
      }
      this.dialog = false;
      this.messageInput.clear();
    } catch (err: any) {
      console.warn(err.message);
    }
    this.sending = false;
  }
}
