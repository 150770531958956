
import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { loadStripe } from "@stripe/stripe-js";
import { Auth, Rates } from '@/store/modules';
import { User } from '@/store/auth';

@Component({
  name: "MeetingRow"
})
export default class extends Vue {
  @Prop() meeting: any;

  addReviewDialog = false;
  loadingAddReview = false;
  comment = '';
  rating = 0;
  error = false;
  hasUserRated = false;
  avgRating = 0;

  get date(): string {
    return this.meeting.date;
  }

  get name(): string {
    return this.meeting.class.name;
  }

  get teacherName(): string {
    return this.meeting.teacherName;
  }

  get paid(): boolean {
    return this.meeting.seat && this.meeting.seat.status !== 'waiting_for_payment';
  }

  get length(): number {
    return this.meeting.length;
  }

  get formattedDate() {
    return new Date(this.date).toLocaleDateString();
  }

  get time() {
    const endDate = new Date(new Date(this.date).valueOf() + this.length * 60 * 1000);

    return `${moment(this.date).format('h:mm A')} - ${moment(endDate).format('h:mm A')}`;
  }

  get passed() {
    return new Date(this.date).valueOf() + this.meeting.length * 60 * 1000 + 15 * 60 * 1000 < Date.now();
  }

  get startsIn15() {
    return this.meeting.status === 'live' && Date.now() > new Date(this.date).valueOf() - 15 * 60 * 1000;
  }

  get live() {
    return this.meeting.status === 'live' && Date.now() > new Date(this.date).valueOf();
  }

  get isOwnClass() {
    return (Auth.user as User).teacherId && (Auth.user as User).teacherId === this.meeting.class.teacherId;
  }

  get teacherAvatar(): string {
    return this.meeting.class.teacher.avatarUrl;
  }

  get teacherId(): string {
    return this.meeting.class.teacherId;
  }

  get classId(): string {
    return this.meeting.classId;
  }

  async getUserRate() {
    // eslint-disable-next-line
    // @ts-ignore
    const rate = await Rates.getRates({ teacherId: this.teacherId, classId: this.classId, userId: Auth.user.id });
    this.hasUserRated = !!rate[0];
    this.comment = rate[0].comment;
    this.rating = rate[0].rating;
    this.avgRating = rate[0].class.avgRating;
  }

  async payForSeat() {
    const response = await Vue.$axios.post(
      `/classes/${this.meeting.classId}/make-reservation`
    );
    const { sessionId } = response.data;
    const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY as string);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { error } = await stripe!.redirectToCheckout({
      sessionId,
    });

    if (error) {
      throw error;
    }
  }
  
  async openRateDialog() {
    this.addReviewDialog = true;
  }

  async addReview() {
    this.loadingAddReview = true;

    try {
      await Rates.addRate({ payload: { teacherId: this.teacherId, classId: this.classId, rating: this.rating, comment: this.comment }});
    } catch(err) {
      this.error = err.message;
    }

    this.hasUserRated = true;
    this.addReviewDialog = false;
    this.loadingAddReview = false;
  }
}
